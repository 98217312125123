/* Prevents scrolling and overscroll behavior on the entire HTML document to avoid the pull to refresh */
html {
  overflow: hidden;
  overscroll-behavior: none;
}


mat-nav-list {
  a.active {
    background-color: #aaa;
    font-weight: 500;
  }
}

.normalWeight {
  font-weight: normal !important;
}

.toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 5px 20px;

  background-color: #fff;
  border-top: 1px solid #ccc;

  .spacer {
    flex-grow: 1;
  }

  button {
    margin: 5px 10px;
  }
}

.color-button {
  color: #266799 !important;
}

.toast {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;

  text-align: center;
  padding: 5px;

  background-color: #fff8e7;
  border-top: 1px solid #ccc;

  button {
    background-color: #fff;
    margin: 5px 10px;
  }
}

tr.mat-footer-row,
tr.mat-row {
  height: 25px !important;
}

.m {
  &-auto {
    margin: auto !important;
  }

  &-none,
  &-0 {
    margin: 0px !important;
  }

  &-1 {
    margin: 1rem !important;
  }

  &b- {
    &0 {
      margin-bottom: 0% !important;
    }

    &05 {
      margin-bottom: 0.5rem !important;
    }

    &1 {
      margin-bottom: 1rem !important;
    }

    &10 {
      margin-bottom: 10px !important;
    }
  }

  &r- {
    &0 {
      margin-right: 0% !important;
    }

    &05 {
      margin-right: 0.5rem !important;
    }

    &1 {
      margin-right: 1rem !important;
    }

    &5 {
      margin-right: 5px !important;
    }

    &10 {
      margin-right: 10px !important;
    }

    &15 {
      margin-right: 15px !important;
    }
  }

  &l- {
    &0 {
      margin-left: 0% !important;
    }

    &05 {
      margin-left: 0.5rem !important;
    }

    &1 {
      margin-left: 1rem !important;
    }

    &5 {
      margin-left: 5px !important;
    }

    &10 {
      margin-left: 10px !important;
    }

    &15 {
      margin-left: 15px !important;
    }

    &125 {
      margin-left: 125px !important;
    }
  }

  &t- {
    &0 {
      margin-top: 0% !important;
    }

    &05 {
      margin-top: 0.5rem !important;
    }

    &1 {
      margin-top: 1rem !important;
    }

    &5 {
      margin-top: 5px !important;
    }

    &10 {
      margin-top: 10px !important;
    }

    &15 {
      margin-top: 15px !important;
    }
  }
}

.p {
  &- {
    &0 {
      padding: 0px !important;
    }

    &05 {
      padding: 0.5rem !important;
    }

    &1 {
      padding: 1rem !important;
    }
  }

  &b- {
    &0 {
      padding-bottom: 0 !important;
    }

    &05 {
      padding-bottom: 0.5rem !important;
    }

    &1 {
      padding-bottom: 1rem !important;
    }

    &15 {
      padding-bottom: 15px !important;
    }
  }

  &t- {
    &0 {
      padding-top: 0 !important;
    }

    &05 {
      padding-top: 0.5rem !important;
    }

    &1 {
      padding-top: 1rem !important;
    }

    &15 {
      padding-top: 15px !important;
    }
  }

  &r- {
    &0 {
      padding-right: 0 !important;
    }

    &05 {
      padding-right: 0.5rem !important;
    }

    &1 {
      padding-right: 1rem;
    }

    &5 {
      padding-right: 5px !important;
    }

    &10 {
      padding-right: 10px !important;
    }

    &15 {
      padding-right: 15px !important;
    }
  }

  &l- {
    &0 {
      padding-left: 0 !important;
    }

    &05 {
      padding-left: 0.5rem !important;
    }

    &1 {
      padding-left: 1rem;
    }

    &5 {
      padding-left: 5px !important;
    }

    &10 {
      padding-left: 10px !important;
    }

    &15 {
      padding-left: 15px !important;
    }
  }
}

.w {
  &-200 {
    width: 200px !important;
  }

  &-100 {
    width: 100px !important;
  }

  &-80 {
    width: 80px !important;
  }

  &-full {
    width: 100% !important;
  }
}

.fs {
  &-12 {
    font-size: 12px !important;
  }

  &-16 {
    font-size: 16px !important;
  }
}

.vw {
  &-80 {
    width: 80vw !important;
  }
}

.fail {
  color: #dc3545 !important;
}

.primary {
  color: $primary !important;
}

.white {
  color: #fff;
}

.primaryBG {
  background-color: $primary !important;
}

.blue {
  color: var(--comunicareBlue);
}

.bg-blue {
  background-color: var(--comunicareBlue);
}

.success {
  color: #28a745 !important;
}

.warn,
.warning {
  color: #ffc107 !important;
}

.accent {
  color: $accent !important;
}

.hide {
  display: none !important;
}

.text-center {
  text-align: center;
}



.mat-typography p {
  margin: 0;
}

.custom-icon {
  height: 1rem !important;
  width: 1rem !important;
}

.custom-icon-small {
  height: 16px !important;
  width: 16px !important;
  font-size: 16px !important;
  line-height: 16px !important;
  vertical-align: auto;
}

.custom-small-btn {
  height: 25px !important;
}

.mat-sort-header-arrow {
  opacity: 1 !important;
  color: #49637b !important;
}

.mat-sort-header-container:not(.mat-sort-header-sorted) .mat-sort-header-arrow {
  opacity: 0.44 !important;
  transform: translateY(0px) !important;
}

.no-padding .mat-dialog-container {
  padding: 0;
}

mat-drawer {
  .mat-button-wrapper {
    white-space: normal !important;
  }
}

.cdk-drag-disabled {
  cursor: default !important;
}

.helpTooltip {
  font-size: 15px !important;
}

a.cgu-link {
  color: unset; // innerHTML styling can't be set inside the longin-page component scss unless encapsulation is set to ViewEncapsulation.None. (seemed risky)
}

.bold {
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.cursor-help {
  cursor: help;
}

.cursor-normal {
  cursor: initial;
}

.pictureButton {
  margin-left: 0.25rem;
  cursor: pointer;
  align-self: center;
  vertical-align: middle;

  &.small {
    transform: scale(0.75);
  }
}

.truncatedNamesTooltip {
  word-break: break-all;
  white-space: normal;
}

.mat-column-action {
  flex-wrap: wrap;
}

.multiline-tooltip {
  white-space: pre-line;
}

.fa-icon {
  height: auto !important;
  width: auto !important;
}

.slider-track {
  stroke: lightgray;
  /* Regular color for the slider track */
  stroke-width: 5px;
  transition: all 0.3s ease;
  /* Smooth transition when adding/removing glow */

  &:hover {
    cursor: pointer;
  }
}

.slider-track-active {
  stroke: #fff;
  filter: drop-shadow(0 0 6px #759FFF);
}


.slider-handle {
  /* Regular styles for the slider handle */
  fill: #86AFCF;
  stroke: none;
  r: 10px;

  &:hover {
    cursor: pointer;
  }
}

.slider-handle-active {
  /* Regular styles for the slider handle */
  fill: #759FFF;

}

.widget {
  mat-toolbar {
    height: 43px;

    p {
      margin: 0px;
      text-align: center;
    }

    .spacerBetween {
      flex: 1 1 auto;
    }
  }

  padding: 0px !important;
  width: 48vw;
  min-width: 600px;
  margin: 5px 0px;

  table {
    width: 100%;

    th:first-child,
    td:first-child {
      max-width: 150px;
    }

    th,
    td {
      padding-left: 10px;
    }
  }

  mat-card-content {
    h3 {
      margin-left: 16px;
    }

    p,
    h4 {
      text-align: center;
    }
  }

  .widget.big {
    width: 95vw !important;
  }
}
