// global style for the responsive dialogs
.full-screen-dialog .mat-dialog-container,
.desktop-screen-dialog .mat-dialog-container {
    padding: 0;

    .ui-dialog-container {
        display: flex;
        flex-direction: column;
        padding: 24px;
        height: 100%;
        max-height: 100svh;
        box-sizing: border-box;
    }

    .ui-dialog-toolbar {
        display: flex;
        justify-content: flex-end;
        position: sticky;
        top: 0;

        button {
            margin-top: -12px;
            margin-right: -15px;
            margin-left: 10px;
        }
    }

    .ui-dialog-content {
        flex-grow: 1;
        overflow: auto;
    }

    .ui-dialog-actions {
        display: flex;
        justify-content: end;
        padding-top: 24px;
        gap: 0.5rem;
        flex-wrap: wrap
    }
}

//  overwrite max-height value on desktop
.desktop-screen-dialog .mat-dialog-container .ui-dialog-container {
    max-height: 80vh; // must match the maxHeight of the dialog set in the responsiveDialogService

}

.full-screen-dialog .mat-dialog-container {
    border-radius: 0px !important;
}
