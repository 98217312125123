// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";
@import "@fortawesome/fontawesome-free/scss/regular.scss";
@import "./palettes.scss";
@import "viewerjs/dist/viewer.css";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$comunicare-dashboard-primary: mat.define-palette($md-comunicare);
$comunicare-dashboard-accent: mat.define-palette($md-comunicare, A200, A100, A400);
// For taskbar
$comunicare-dashboard-grey: mat.define-palette($md-comunicare, 50);

// The warn palette is optional (defaults to red).
$comunicare-dashboard-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$comunicare-dashboard-theme: mat.define-light-theme($comunicare-dashboard-primary,
    $comunicare-dashboard-accent,
    $comunicare-dashboard-warn);

// Create your Sass color vars (will be available in all the project)
$primary: mat.get-color-from-palette($comunicare-dashboard-primary);
$accent: mat.get-color-from-palette($comunicare-dashboard-accent);
$warn: mat.get-color-from-palette($comunicare-dashboard-warn);
$comunicareBlue: #266799;

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($comunicare-dashboard-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  --comunicareBlue: #266799;
  --comunicarePrimary: #81aed3;
  --comunicareSecondary: #b3cee4;
  --comunicareTertiary: #e1ebf4;
  --warn: #dc3545;
  --lightGreen: #daefe2;
  --lightYellow: #fffbd6;
}

.ngx-timepicker {
  padding-bottom: 10px !important;
}

@import "assets/comunicare-custom.scss";
@import "assets/comunicare-widget.scss";
@import "assets/comunicare-dialog.scss";
